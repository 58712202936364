<template>
  <v-card-text class="pb-0 mt-6">
    <BlurredBackdropUpselling
      :is-blurred="!getFeature(featureNames.CMS).config.hasWidgets"
    >
      <v-row class="mb-2">
        <v-col>
          <h3 class="mb-4">
            {{ $t('landingpages.snippet.code', { textCodeTitle: '' }) }}:
          </h3>

          <h4 class="mt-6">
            {{ $t('landingpages.snippet.component.body') }}
          </h4>
          <CodeSnippet :snippet="`${srcSnippet}\n${componentSnippet}`" />
        </v-col>
      </v-row>
      <v-row class="mb-2">
        <v-col>
          <h3 class="mb-4">
            {{ $t('labels.preview') }}:
          </h3>

          <div
            v-if="mounted"
            class="d-flex justify-center pa-6 grey"
          >
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="componentSnippet" />
          </div>
        </v-col>
      </v-row>
    </BlurredBackdropUpselling>
  </v-card-text>
</template>

<script>
import GET_CMS_ORGANIZATION from './GetCmsOrganization.gql'
import CodeSnippet from './CodeSnippet.vue'
import feature from '@/mixins/feature'
import BlurredBackdropUpselling from '@/components/BlurredBackdropUpselling.vue'

export default {
  components: {
    CodeSnippet,
    BlurredBackdropUpselling
  },
  mixins: [feature],
  props: {
    landingpageSlug: { type: String, default: null },
    readableLandingpage: { type: String, default: null },
    landingpageType: { type: String, default: '' }
  },
  data () {
    return {
      cmsOrganization: {},
      mounted: false
    }
  },
  computed: {
    component () {
      return `${this.cmsOrganization.widgetUrl}/components/${this.cmsOrganization.id}/btm-widget/${this.cmsOrganization.defaultLocale}`
    },
    srcSnippet () {
      return `<script src="${this.component}" defer><script>`
    },
    componentSnippet () {
      return `<btm-widget widget="${this.widget}" ${this.parameters} />`
    },
    widget () {
      return {
        CHECKLIST: 'download',
        GUIDE: 'download',
        RETURN_CALL: 'return-call',
        VALUATION: 'valuation',
        PLOT_VALUATION: 'plot-valuation',
        PROPERTY_REQUEST: 'property-request',
        LIFE_ANNUITY: 'life-annuity',
        PARTIAL_SALE: 'partial-sale',
        QUIZZARD: 'quizzard',
        LIST: 'list'
      }[this.landingpageType]
    },
    parameters () {
      return {
        CHECKLIST: `slug="${this.landingpageSlug}"`,
        GUIDE: `slug="${this.landingpageSlug}"`,
        QUIZZARD: `slug="${this.landingpageSlug}"`,
        LIST: `slug="${this.landingpageSlug.split('-')[1] === 'ratgeber' ? 'GUIDE' : 'CHECKLIST'}"`
      }[this.landingpageType] || ''
    }
  },
  watch: {
    cmsOrganization () {
      if (!this.mounted) {
        const script = document.createElement('script')
        script.src = this.component
        script.async = true
        document.body.appendChild(script)
        this.mounted = true
      }
    }
  },
  apollo: {
    cmsOrganization: {
      query: GET_CMS_ORGANIZATION,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    }
  }
}
</script>
